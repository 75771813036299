import React, { useRef, useState, useEffect } from "react";
import * as styles from "./ContactForm.module.scss";
import { ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import Wizard from "./Wizard";
import { Col, Row } from "../layout/Grid";
import ScrollReveal from "../layout/ScrollReveal";
import classNames from "classnames";

const WizardStep = ({ children }) => children;

const InputField = ({ id, inputName, placeholder, type, component }) => {
  const [inFocus, setInFocus] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setInFocus(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [ref]);
  return (
    <div
      ref={ref}
      onClick={() => setInFocus(true)}
      onKeyDown={() => setInFocus(true)}
      className={classNames(styles.inputField, inFocus ? styles.focus : null)}
      role="presentation"
    >
      <Field
        component={component ? component : "input"}
        id={id}
        name={inputName}
        placeholder={placeholder}
        type={type ? type : "text"}
        className={styles["input"]}
        autoComplete="false"
      />
    </div>
  );
};

const ContactForm = () => {
  return (
    <Row>
      <Col width="40%">
        <ScrollReveal effect="slide-right" delay={50}>
          <div className="h3">
            Interested in working with us? Simply fill out the form
          </div>
        </ScrollReveal>
      </Col>
      <Col width="50%">
        <ScrollReveal effect="slide-left" delay={50}>
          <Wizard
            initialValues={{
              name: "",
              email: "",
              phone: "",
              companyName: "",
              projectType: "",
              project: "",
              reference: "",
            }}
          >
            <WizardStep
              onSubmit={() => console.log("Step1 onSubmit")}
              validationSchema={Yup.object({
                name: Yup.string().required("Please fill in your name"),
              })}
            >
              <label htmlFor="name" className={styles["label"]}>
                Hi, what's your name?
              </label>
              <InputField id="name" inputName="name" placeholder="John Smith" />
              <ErrorMessage className="msg-error" component="div" name="name" />
            </WizardStep>
            <WizardStep
              onSubmit={() => console.log("Step2 onSubmit")}
              validationSchema={Yup.object({
                phone: Yup.string()
                  .required("Please fill in your contact number")
                  .matches(/^[0-9]+$/, "Please enter digits only")
                  .min(10, "Please enter a valid contact number")
                  .max(12, "Please enter a valid contact number"),
              })}
            >
              <label htmlFor="phone" className={styles["label"]}>
                How can we contact you?
              </label>
              <InputField
                id="phone"
                inputName="phone"
                placeholder="0123456789"
              />
              <ErrorMessage
                className="msg-error"
                component="div"
                name="phone"
              />
            </WizardStep>
            <WizardStep
              onSubmit={() => console.log("Step3 onSubmit")}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Please fill in your email address")
                  .email("Please enter a valid email"),
              })}
            >
              <label htmlFor="email" className={styles["label"]}>
                What's your email address?
              </label>
              <InputField
                id="email"
                inputName="email"
                placeholder="johnsmith@example.com"
                type="email"
              />
              <ErrorMessage
                className="msg-error"
                component="div"
                name="email"
              />
            </WizardStep>

            <WizardStep
              onSubmit={() => console.log("Step4 onSubmit")}
              validationSchema={Yup.object({
                companyName: Yup.string().required(
                  "Please fill in your company name"
                ),
              })}
            >
              <label htmlFor="companyName" className={styles["label"]}>
                What's your company name?
              </label>
              <InputField
                id="companyName"
                inputName="companyName"
                placeholder="Company / Organisation"
              />
              <ErrorMessage
                className="msg-error"
                component="div"
                name="companyName"
              />
            </WizardStep>
            <WizardStep onSubmit={() => console.log("Step5 onSubmit")}>
              <label htmlFor="projectType[]" className={styles["label"]}>
                I'm Interested in...
              </label>
              <ul className={styles.projectType}>
                <li>
                  <Field
                    type="checkbox"
                    id="web"
                    name="projectType[]"
                    value="Web"
                  />
                  <label htmlFor="projectType[]">
                    Web<span></span>
                  </label>
                </li>
                <li>
                  <Field
                    type="checkbox"
                    id="ecommerce"
                    name="projectType[]"
                    value="Ecommerce"
                  />
                  <label htmlFor="projectType[]">
                    Ecommerce<span></span>
                  </label>
                </li>
                <li>
                  <Field
                    type="checkbox"
                    id="software"
                    name="projectType[]"
                    value="Software"
                  />
                  <label htmlFor="projectType[]">
                    Software<span></span>
                  </label>
                </li>
                <li>
                  <Field
                    type="checkbox"
                    id="mobile"
                    name="projectType[]"
                    value="Mobile App"
                  />
                  <label htmlFor="projectType[]">
                    Mobile App<span></span>
                  </label>
                </li>
              </ul>
              <ErrorMessage
                className="msg-error"
                component="div"
                name="projectType[]"
              />
            </WizardStep>
            <WizardStep
              onSubmit={() => console.log("Step6 onSubmit")}
              validationSchema={Yup.object({
                project: Yup.string().required(
                  "Please describe on your project"
                ),
              })}
            >
              <label htmlFor="project" className={styles["label"]}>
                How can we help?
              </label>
              <InputField
                component="textarea"
                id="project"
                inputName="project"
                placeholder="Describe your project"
              />
              <ErrorMessage
                className="msg-error"
                component="div"
                name="project"
              />
            </WizardStep>
            <WizardStep onSubmit={() => console.log("form onSubmit")}>
              <label htmlFor="reference" className={styles["label"]}>
                Any references?
              </label>
              <InputField
                component="textarea"
                id="reference"
                inputName="reference"
                placeholder="[opt.]"
              />
              <ErrorMessage
                className="msg-error"
                component="div"
                name="reference"
              />
            </WizardStep>
          </Wizard>
        </ScrollReveal>
      </Col>
    </Row>
  );
};

export default ContactForm;
