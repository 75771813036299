import React, { useState, useEffect } from "react";
import * as styles from "./Wizard.module.scss";
import { Form, Formik } from "formik";
import qs from "qs";
import axios from "axios";
import {
  loadRecaptcha,
  removeRecaptcha,
  getRecaptchaToken,
} from "../../utils/googleRecaptcha";

const Wizard = ({ children, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  return (
    <Formik
      initialValues={snapshot}
      validationSchema={step.props.validationSchema}
      onSubmit={async (values, bag) => {
        if (step.props.onSubmit) {
          await step.props.onSubmit(values, bag);
        }
        if (isLastStep) {
          // return step.props.onSubmit(values, bag)
          setLoading(true);
          console.log(values);
          try {
            const recaptchaCode = await getRecaptchaToken("contact_form");
            const response = await axios({
              method: "post",
              url: "https://dev.simplicitic.com/simplicitic-mail/ContactFormMailing.php",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              data: qs.stringify({ ...values, token: recaptchaCode }),
              withCredentials: false,
            });
            console.log(response);
            if (response.data.success) {
              bag.resetForm();
              bag.setStatus({
                success: true,
                message: "Message has been sent!",
              });
              bag.setSubmitting(false);
            } else {
              bag.setStatus({
                success: false,
                message: "Error sending form. Please try again later.",
              });
            }
          } catch (error) {
            bag.setStatus({
              success: false,
              message: `${error}. Please try again later.`,
            });
          }
          setLoading(false);
        } else {
          bag.setTouched({});
          next(values);
        }
      }}
    >
      {({ status }) => (
        <Form>
          <div className={styles["input_group"]}>{step}</div>
          <div className={styles["button"]}>
            {isLastStep ? (
              <>
                <button disabled={loading} type="submit" className="btn">
                  Submit
                </button>
                {status && status.message && (
                  <div
                    className={`alert ${
                      status.success ? "alert-success" : "alert-error"
                    }`}
                  >
                    {status.message}
                  </div>
                )}
                {loading ? <div className="loading"></div> : ""}
              </>
            ) : (
              <button
                disabled={Formik.isSubmitting}
                type="submit"
                className="btn"
              >
                Continue
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Wizard;
