import React from "react";
import * as styles from "./ContactLocation.module.scss";
import VectorMap from "../../images/map.svg";
import ScrollReveal from "../layout/ScrollReveal";
import ContactForm from "./ContactForm";

const ContactLocation = () => {
  return (
    <>
      <section>
        <div className="title">Contact</div>
        <div className="section wrapper">
          <ContactForm />
          <ScrollReveal effect="slide-up" delay={50}>
            <div className={styles["map"]}>
              <img src={VectorMap} alt="Vector Map" />
            </div>
          </ScrollReveal>
        </div>
      </section>
      <div className={styles["googlemap"]}>
        <iframe
          src="https://snazzymaps.com/embed/351169"
          title="Simplicitic Innovations"
        />
      </div>
    </>
  );
};

export default ContactLocation;
